import axios from "axios";
import Core from "../helpers/Core";
import Enviroment from "../helpers/Enviroment";
// import Helpers from "../helpers/Helpers"; // Eliminado porque no se usa
import User from "../helpers/User";
import SessionUser from "./SessionUser";

export default class Rest {
  enable_mockup = false;
  response_mockup = null;
  api = "";
  segment_api = "";
  end_point_geterate_token = "";

  constructor() {
    this.endpoint = "";
    this.params = null;
    this.base_url = "https://soy.esenttia.co";  // Cambia esto
    this.end_point_geterate_token = this.base_url + "/core/token-generate-public";
  }

  enableTokenSys() {
    Core.setTokenSys();
  }

  setBaseUrl(base_url) {
    this.base_url = base_url;
  }

  setEndPoint(endpoint) {
    this.endpoint = endpoint;
  }

  loadEndPointStandarLoadFile() {
    let end_point =
      Enviroment.getBackArquitecture() == "V2"
        ? "api/crcore/cr-archivos-cargar"
        : "/api/archivos-cargar";
    this.endpoint = this.base_url + end_point;
  }

  loadEndPointStandarImageSignature() {
    let end_point =
      Enviroment.getBackArquitecture() == "V2"
        ? "api/crcore/resources-save-image-signature"
        : "/api/core/resources-save-image-signature";
    this.endpoint = this.base_url + end_point;
  }

  loadEndPointStandarThumbnail() {
    let end_point =
      Enviroment.getBackArquitecture() == "V2"
        ? "api/crcore/resources-save-image"
        : "api/core/resources-save-image";
    this.endpoint = this.base_url + end_point;
  }

  setParams(params) {
    this.params = params;
  }

  post() {
    let urlFinal =  this.endpoint;
    let dataSend = this.params;
    return this.peticion("POST", urlFinal, dataSend);
  }

  get() {
    let urlFinal = this.base_url + this.endpoint + this.params;
    return this.peticion("GET", urlFinal);
  }

  put(ente_id) {
    let urlFinal = ente_id 
      ? `${this.base_url + this.endpoint}/${ente_id}`
      : this.base_url + this.endpoint;
    let dataSend = this.params;
    return this.peticion("PUT", urlFinal, dataSend);
  }

  delete(ente_id) {
    let urlFinal = `${this.base_url + this.endpoint}/${ente_id}`;
    return this.peticion("DELETE", urlFinal);
  }
  patch(ente_id) {
    let urlFinal = ente_id 
      ? `${this.base_url + this.endpoint}/${ente_id}`
      : this.base_url + this.endpoint;
    let dataSend = this.params;
    return this.peticion("PATCH", urlFinal, dataSend);
  }

  enableMockup(response) {
    this.enable_mockup = true;
    this.response_mockup = response;
  }

  peticion(method, urlFinal, dataSend) {
    if (this.enable_mockup) {
      return this.peticionMockup();
    }
    return new Promise((resolve, reject) => {
      if (Enviroment.getTypeToken() == "PUBLIC") {
        this.getToken((token) => {
          this._peticionSend(resolve, reject, token, method, urlFinal, dataSend);
        });
      } else {
        var token = Core.getToken();
        this._peticionSend(resolve, reject, token, method, urlFinal, dataSend);
      }
    });
  }

  _peticionSend(resolve, reject, token, method, urlFinal, dataSend) {
    if (!token) {
      token = "ERROR";
    }
    console.log("URL Final:", urlFinal); 
    var params = {};
    var sData = {};
    var headers = {
      Accept: "application/json, text/javascript",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: `${token}`,
      "Auth-visit-id": `${Core.getVisitId()}`,
    };

    if (method === "PUT") {
      sData = this.serialize(dataSend);
    } else if (method === "POST") {
      var infoForm = new FormData();
      for (var key in dataSend) {
        infoForm.set(key, `${dataSend[key]}`);
      }
      sData = infoForm;
    }

    const config = {
      method: method,
      url: urlFinal.replace('null', ''),
      data: sData,
      params: params,
      headers: headers,
    };
    
    console.log(config.url);

    axios(config)
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject(new Error("No se encontró la propiedad 'data' en la respuesta"));
        }
      })
      .catch((e) => {
        // Verifica si e.response está definido
        if (e.response && e.response.data) {
          reject(e.response.data);
        } else {
          // Si e.response no está definido, maneja el error de otra manera
          reject(new Error("Error en la petición: " + e.message));
        }
      });
  }


  getToken(resolve_calback) {
    let current_time = new Date().getTime();
    let renew_token = Number(localStorage.getItem("token_renew"));

    if (renew_token == 0 || Number(current_time) > Number(renew_token)) {
      let session_user_renew = new SessionUser();
      session_user_renew.destroyToken();
    }

    if (User.getToken()) {
      resolve_calback(User.getToken());
    } else {
      var headers = {
        Accept: "application/json, text/javascript",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: `${Enviroment.getTokenAccount()}`,
        "Auth-visit-id": `${Core.getVisitId()}`,
      };

      const config = {
        method: "GET",
        url: this.end_point_geterate_token,
        headers: headers,
      };

      axios(config)
        .then((response) => {
          localStorage.setItem(
            "token_renew",
            `${current_time + Enviroment.getTokenMinutesExpires() * 60 * 1000}`
          );
          localStorage.setItem("token", response.data.data);
          resolve_calback(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  peticionMockup() {
    return new Promise((resolve, reject) => {
      var token = Core.getToken();
      if (token === "") {
        token = "XXX-1-1-1-XXX";
      }
      var headers = {
        Accept: "application/json, text/javascript",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: `${token}`,
      };

      var method = "POST";
      var infoForm = new FormData();
      var urlFinal = `${this.base_url}api/crcore/cr-staging-mockup`;

      infoForm.set("jsonstring", JSON.stringify(this.response_mockup));

      const config = {
        method: method,
        url: urlFinal,
        data: infoForm,
        headers: headers,
      };

      axios(config)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e.response.data));
    });
  }

  file(fileInput) {
    let method = "POST";
    let urlFinal = this.endpoint;
    let dataSend = this.params;
    let clave = "archivo";

    console.log("urlFinal", urlFinal);
    var external_load_file = Enviroment.getExternalLoadFile();
    if (external_load_file == "false") {
      urlFinal = Enviroment.getExternalUrlLoadFile();
      console.log("urlFinal-modificada", urlFinal);
    }

    return new Promise((resolve, reject) => {
      if (!fileInput) {
        reject("False");
      }

      var token = localStorage.getItem("token");
      var infoForm = new FormData();

      for (var key in dataSend) {
        infoForm.set(key, `${dataSend[key]}`);
      }
      infoForm.set(clave, fileInput);

      const config = {
        method: method,
        url: urlFinal,
        data: infoForm,
        headers: {
          Accept: "application/json, text/javascript",
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      };

      axios(config)
      .then((response) => {
        console.log(" E "); // Indica que llegamos aquí
        console.log("Respuesta completa:", response); // Imprime la respuesta completa
        resolve(response.data); // Asegúrate de que response.data tiene el formato esperado
      })
      .catch((e) => {
        console.log(" F ");
        console.error("Error en la solicitud:", e); // Imprime el error
        reject(e);
      });
    
    });
  }

  serialize(obj) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  peticionMockupV2(type, data, string_data_mockup) {
    return new Promise((resolve, reject) => {
      var token = Core.getToken();
      if (token === "") {
        token = "XXX-1-1-1-XXX";
      }
      var headers = {
        Accept: "application/json, text/javascript",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: `${token}`,
      };

      var method = "POST";
      var infoForm = new FormData();
      var urlFinal = Enviroment.getUrlMockcup() + this.endpoint;

      for (let info in this.params) {
        if (this.params.hasOwnProperty(info)) {
          infoForm.set(info, this.params[info]);
        }
      }

      infoForm.set("METHOD", type);
      infoForm.set("jsonstring", JSON.stringify(string_data_mockup));

      const config = {
        method: method,
        url: urlFinal,
        data: infoForm,
        headers: headers,
      };

      axios(config)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e.response.data));
    });
  }
}
