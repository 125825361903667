import Vue from 'vue';

const EventBus = new Vue();
const translations = {
    en: {

      welcomeMessage: "Welcome to the start page.",
      start: "Start",
      description: "This is the starting point of your journey.",

      menu: {
        list: "List",

        esenttia: "Esenttia",
        users: "Users",
        umanage: "Manage",
        upositions: "User Positions",
        templates: "Templates",
        tlist: "List",
        tmanage: "Manage",
        portfolio: "Portfolio",
        plist: "List",
        pmanage: "Manage",
        company: "Company",
        cupdate: "Update",
        profile: "Profile",
        myProfile: "My Profile",
      },

      //listusers
      breadcrumb: {
        home: "Home",
        users: "Users",
        userList: "User List",
        create: "Create",
        update: "Update",
        positionsList: "List of Positions",
        templates: "Templates",
        list: "List",
        portfolio: "Portfolio",
        manage: "Manage",
        editCompany: "Edit Company",
        company: "Company",
        profile: "Profile",




      },
      title: {
        userList: "User List",
        userDetail: "User Detail",
        createUser: "Create User",
        updateUser: "Update User",
        updatePassword: "Update Password",
        positionsList: "List of Positions",
        createPosition: "Create Position",
        editPosition: "Edit Position",
        templateList: "Template List",
        createTemplate: "Create Template",
        updateTemplate: "Update Template",
        portfolioList: "Portfolio List",
        managePortfolio: "Manage Portfolio",
        editCompany: "Edit Company",
        myProfile: "My Profile",
        qrCode: "QR Code",



      },
      search: {
        label: "Search",
      },
      button: {
        createUser: "Create User",
        createPosition: "Create Position",
        createTemplate: "Create Template",
        disable: "Disable",
        enable: "Enable",
        close: "Close",
        deletePhoto: "Delete Photo",
        save: "Save",
        update: "Update",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        viewProfile: "View Profile",
        viewDetail: "View Detail",
        createPortfolio: "Create Portfolio",
        deleteLocation: "Delete Location",
        addAnotherLocation: "Add Another Location",
        viewCompanyInfo: "View Company Information",
        viewOnGoogleMaps: "View on Google Maps",
        viewAddressList: "View Address List",
        viewCompany: "View Company",
        editProfile: "Edit Profile",
      viewQrCode: "View QR Code",




      },
      user: {
        state: {
          active: "Active",
          blocked: "Blocked",
        },
        names: "Names",
        surnames: "Surnames",
        phone: "Phone",
        email: "Email",
      },
      tooltip: {
        viewDetail: "View Details",
      },
      table: {
        headers: {
          names: "Names",
          surnames: "Surnames",
          type: "Type",
          state: "State",
          email: "Email",
          actions: "Actions",
          title: "Title",
          code: "Code",
          image: "Image",
          description: "Description",
        },
      },
      form: {
        profilePhoto: "Profile Photo",
        firstName: "First Name",
        secondName: "Second Name",
        firstSurname: "First Surname",
        secondSurname: "Second Surname",
        documentType: "Document Type",
        identityNumber: "Identity Number",
        userType: "User Type",
        email: "Email",
        position: "Position",
        address: "Address",
        phone: "Phone",
        minChars: "Minimum 8 characters",
        password: "Password",
        confirmPassword: "Confirm Password",
        website: "Website",
        template: "Template",
        password: "Password",
        confirmPassword: "Confirm Password",
        confirmPasswordHint: "Click the eye icon to show or hide the confirmation password.",
        passwordRequirements: "The password must include at least:",
        title: "Title",
        image: "Image",
        portfolioImage: "Portfolio Image",
        url: "URL",
        buttonText: "Button Text",
        description: "Description",
        enable: "Enable",
        companyName: "Company Name",
        image: "Image",
        portfolioImage: "Portfolio Image",
        address: "Address",
        googleMapsLink: "Google Maps Link",
        schedule: "Schedule",
        facebookLink: "Facebook Link",
        instagramLink: "Instagram Link",
        twitterLink: "Twitter Link",
        linkedinLink: "LinkedIn Link",
        youtubeLink: "YouTube Link",
        email: "Email",
        buttons: "Buttons",
        url1: "URL 1",
        description1: "Description 1",
        url2: "URL 2",
        description2: "Description 2",
        url3: "URL 3",
        description3: "Description 3",
        switchboard: "Switchboard",
        type: "Type",
        phoneNumber: "Phone Number",

        requirement: {
          uppercase: "One uppercase letter",
          lowercase: "One lowercase letter",
          number: "One number",
          specialChar: "One special character (@, $, !, %, *, #, ?, &)",
        },
        positionName: "Position Name",
        positionDescription: "Position Description",
      },
      validation: {
        required: "This field is required",
        invalidUrl: "Invalid URL",
        invalidEmail: "Invalid email",

      },
      dialog: {
        templateDetails: "Template Details",
        name: "Name",
        code: "Code",
      },
      messages: {
        photoUrl: "Photo URL",
        noPhoto: "No photo uploaded",
        photoDeleted: "Photo deleted",
      },
      navigation: {
        profile: "Contact",
        company: "Company",
        portfolio: "Portfolio",
      },
      contact: "Contact",
      company: "Company",
      portfolio: "Portfolio",
      openingHours: "Opening Hours",
      switchboard: "Switchboard",
      viewOnGoogleMaps: "View on Google Maps",
      viewListOfAddresses: "View list of addresses",
    },
    es: {
      welcomeMessage: "Bienvenido a la página de inicio.",
      start: "Inicio",
      description: "Este es el punto de partida de tu viaje.",
      contact: "Contacto",
      company: "Empresa",
      portfolio: "Portafolio",
      openingHours: "Horario de atención",
      switchboard: "Conmutador",
      viewOnGoogleMaps: "Ver en Google Maps",
      viewListOfAddresses: "Ver listado de direcciones",

      menu: {
        list: "Listado",
        esenttia: "Esenttia",
        users: "Usuarios",
        umanage: "Gestionar",
        upositions: "Cargos de Usuarios",
        templates: "Plantillas",
        tlist: "Listado",
        tmanage: "Gestionar",
        portfolio: "Portafolio",
        plist: "Listado",
        pmanage: "Gestionar",
        company: "Empresa",
        cupdate: "Actualizar",
        profile: "Perfil",
        myProfile: "Mi Perfil",
      },

      //userlist
      breadcrumb: {
        home: "Inicio",
        users: "Usuarios",
        userList: "Listado de usuarios",
        create: "Crear",
        update: "Actualizar",
        positionsList: "Listado de Cargos",
        templates: "Plantillas",
        list: "Listado",
        portfolio: "Portafolio",
        manage: "Gestionar",
        company: "Empresa",
        editCompany: "Editar empresa",
        profile: "Perfil",
        




      },
      title: {
        userList: "Listado de usuarios",
        userDetail: "Detalle del usuario",
        createUser: "Crear Usuario",
        updateUser: "Actualizar Usuario",
        updatePassword: "Actualizar Contraseña",
        positionsList: "Listado de Cargos",
        createPosition: "Crear Cargo",
        editPosition: "Editar Cargo",
        templateList: "Listado de Plantillas",
        createTemplate: "Crear Plantilla",
        updateTemplate: "Actualizar Plantilla",
        portfolioList: "Listado de Portafolio",
        managePortfolio: "Gestionar Portafolio",
        editCompany: "Editar empresa",
        myProfile: "Mi perfil",
        qrCode: "Código QR",
 




      },
      search: {
        label: "Buscar",
      },
      button: {
        createPortfolio: "Crear Portafolio",
        viewCompanyInfo: "Ver información de la empresa",
        createUser: "Crear Usuario",
        viewProfile: "Ver perfil",
        deletePhoto: "Eliminar Foto",
        save: "Guardar",
        update: "Actualizar",
        createPosition: "Crear Cargo",
        disable: "Deshabilitar",
        enable: "Habilitar",
        edit: "Editar",
        delete: "Eliminar",
        cancel: "Cancelar",
        createTemplate: "Crear Plantilla",
        viewDetail: "Ver Detalle",
        close: "Cerrar",
        deleteLocation: "Eliminar ubicación",
        addAnotherLocation: "Añadir otra ubicación",
        viewOnGoogleMaps: "Ver en Google Maps",
        viewAddressList: "Ver listado de direcciones",
        viewCompany: "Ver Empresa",
        editProfile: "Editar perfil",
        viewQrCode: "Ver código QR",
      },
      user: {
        state: {
          active: "Activo",
          blocked: "Bloqueado",
        },
        names: "Nombres",
        surnames: "Apellidos",
        phone: "Teléfono",
        email: "Correo Electrónico",
      },
      tooltip: {
        viewDetail: "Ver Detalles",
      },
      table: {
        headers: {
          names: "Nombres",
          surnames: "Apellidos",
          type: "Tipo",
          state: "Estado",
          email: "Correo Electrónico",
          actions: "Acciones",
          title: "Título",
          code: "Código",
          image: "Imagen",
          description: "Descripción",
        },
      },
      form: {
        profilePhoto: "Foto de Perfil",
        firstName: "Primer Nombre",
        secondName: "Segundo Nombre",
        firstSurname: "Primer Apellido",
        secondSurname: "Segundo Apellido",
        documentType: "Tipo de Documento",
        identityNumber: "Número de Identidad",
        userType: "Tipo de Usuario",
        email: "Correo Electrónico",
        position: "Cargo",
        address: "Dirección",
        phone: "Teléfono",
        minChars: "Mínimo 8 caracteres",
        password: "Contraseña",
        confirmPassword: "Confirmar Contraseña",
        website: "Sitio web",
        template: "Plantilla",
        password: "Contraseña",
        confirmPassword: "Confirmar Contraseña",
        confirmPasswordHint: "Haga clic en el icono del ojo para mostrar u ocultar la confirmación de contraseña.",
        passwordRequirements: "La contraseña debe incluir al menos:",
        portfolioImage: "Imagen del portafolio",
        address: "Dirección",
        googleMapsLink: "Enlace Google Maps",
        companyName: "Nombre de la empresa",
        schedule: "Horario de atención",
        facebookLink: "Enlace Facebook",
        instagramLink: "Enlace Instagram",
        twitterLink: "Enlace Twitter",
        linkedinLink: "Enlace Linkedin",
        youtubeLink: "Enlace Youtube",
        email: "Correo electrónico",
        buttons: "Botones",
        url1: "URL 1",
        description1: "Descripción 1",
        url2: "URL 2",
        description2: "Descripción 2",
        url3: "URL 3",
        description3: "Descripción 3",
        switchboard: "Conmutador",
        type: "Tipo",
        phoneNumber: "Teléfono",

        requirement: {
            uppercase: "Una letra mayúscula",
            lowercase: "Una letra minúscula",
            number: "Un número",
            specialChar: "Un carácter especial (@, $, !, %, *, #, ?, &)",
      },
      positionName: "Nombre del Cargo",
      positionDescription: "Descripción del Cargo",
      title: "Título",
      image: "Imagen",
      portfolioImage: "Imagen del Portafolio",
      url: "URL",
      buttonText: "Texto del Botón",
      description: "Descripción",
      enable: "Habilitar",

      },
      validation: {
        required: "Este campo es obligatorio",
        invalidUrl: "URL no válida",
        invalidEmail: "Correo electrónico no válido",

      },
      dialog: {
        templateDetails: "Detalles de la Plantilla",
        name: "Nombre",
        code: "Código",
      },
      messages: {
        photoUrl: "URL de la foto",
        noPhoto: "No hay foto cargada",
        photoDeleted: "Foto eliminada",
      },
      navigation: {
        profile: "Contacto",
        company: "Empresa",
        portfolio: "Portafolio",
      },
      
    
    },
  };
  
  
  let currentLocale = "en";
  
  const setLocale = (locale) => {
    console.log(`Cambiando idioma a: ${locale}`);
    currentLocale = locale;
    EventBus.$emit('localeChanged', locale); // Verifica si se emite el evento
    console.log('Evento localeChanged emitido'); // Confirmar que el evento fue disparado
  };
  
  
  
  const getCurrentLocale = () => {
    return currentLocale;
  };
  
  const t = (key) => {
    const keys = key.split(".");
    let value = translations[currentLocale];
    keys.forEach((k) => {
      value = value ? value[k] : key;
    });
    return value || key;
  };
  
  export default {
    setLocale,
    getCurrentLocale,
    t,
    EventBus,
    translations,
  };
  