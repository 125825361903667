<template>
  <div>
    <br>
    <br>
    <br>
    <br>
    <p>{{ translate('welcomeMessage') }}</p>
    <h1>{{ translate('start') }}</h1>
    <p>{{ translate('description') }}</p>
  </div>
</template>

<script>
import TranslationService from '@/services/translationService';

export default {
  name: 'WelcomePage',
  mounted() {
    TranslationService.EventBus.$on('localeChanged', () => {
      console.log('Evento localeChanged recibido en Hello.vue'); // Verifica que el evento se reciba
      this.$forceUpdate(); // Actualiza el componente cuando cambia el idioma
    });
  },
  methods: {
    translate(key) {
      try {
        const translation = TranslationService.t(key);
        console.log(`Traducción para ${key}: ${translation}`); // Verifica las traducciones
        return translation;
      } catch (error) {
        console.error(`Error al traducir la clave: ${key}`, error);
        return key; // Devuelve la clave como texto por defecto
      }
    },
  },
};
</script>

<style>
/* Agrega aquí estilos adicionales si es necesario */
</style>
