import UI from '../../../core/helpers/UI';
// Removed the unused import: import File from "../../../core/helpers/File";
import ListService from '../services/ListService';
import TranslationService from "@/services/translationService"; // Importa TranslationService

class ListController {
  context;
  list_service;

  constructor(context) {
    this.context = context;
    this.list_service = new ListService();

    // Configurar encabezados traducidos
    this.setupHeaders();

    // Escuchar cambios de idioma para actualizar los encabezados
    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Cambio de idioma detectado en ListController");
      this.setupHeaders(); // Actualiza los encabezados dinámicamente
    });

    this._listBriefcases();
  }

  setupHeaders() {
    this.context.headers = [
      { text: "", value: "checkbox" },
      { text: TranslationService.t("table.headers.title") || "Título", value: "title" },
      { text: TranslationService.t("table.headers.image") || "Imagen", value: "photo" },
      { text: TranslationService.t("table.headers.description") || "Descripción", value: "description" },
      { text: "", value: "actions" }, // Este puede quedarse vacío
    ];

    console.log("Encabezados configurados:", this.context.headers); // Log para depuración
  }

  _listBriefcases() {
    this.context.lists = [];
    UI.loading("show");
    this.list_service
      .listBriefcases()
      .then((r) => {
        if (r.success == true) {
          this.context.lists = r.data.items;

          UI.loading("hide");
        }
      })
      .catch((e) => {
        UI.loading("hide");
        UI.mensajeBad("", e.msg);
      });
  }

  toggleStateBriefcase(briefcase_id, position, state) {
    UI.loading("show");
    this.list_service
      .toggleStateBriefcase(briefcase_id)
      .then((r) => {
        if (r.success == true) {
          UI.mensajeOk("", r.msg);

          if (state == 1) {
            this.context.lists[position].state = 2;
          } else {
            this.context.lists[position].state = 1;
          }

          UI.loading("hide");
        }
      })
      .catch((e) => {
        UI.loading("hide");
        UI.mensajeBad("", e.msg);
      });
  }

  openDetail(briefcase_id) {
    this.context.$set(this.context.dialog_detail, briefcase_id, true);
  }

  closeDetail(briefcase_id) {
    this.context.$set(this.context.dialog_detail, briefcase_id, false);
  }
}

export default ListController;
