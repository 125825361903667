<template>
  <div>
    <!-- Botón de cambio de idioma en la parte superior -->
    <div class="language-toggle d-flex justify-content-end mb-3">
      <button class="btn btn-primary mx-1" @click="toggleLanguage">{{ buttonText }}</button>
    </div>

    <!-- Barra de navegación inferior -->
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.profile') }}</span>
        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.company') }}</span>
        <img src="../../_core/images/iconos.png" class="icono-family2" alt="">
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.portfolio') }}</span>
        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>

      <!-- Botón de cambio de idioma dentro de la barra -->
      <v-btn class="language-toggle" min-height="inherit" height="auto" @click="toggleLanguage">
        <span class="fw-700 fz-14 mt-1 text-white">{{ buttonText }}</span>
        <v-icon class="fz-34 text-white">mdi-translate</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <div class="bg-page mt-5">
      <img src="../../_core/images/bg-profile.jpg" alt="">

      <section class="px-3 pt-3 pb-4 pb-md-5 section-public section-public-profile">
        <!-- Contenido de la sección pública -->
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-9 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <img max-width="200px" width="100%" class="d-block mx-auto" src="../../_core/images/logo_inicio.png" />
          </div>
          <div class="mt-2 px-3" v-if="template">
            <div class="template w-100 overflow-hidden" v-html="template"></div>
          </div>
        </div>

        <div class="row justify-content-center pt-3 pt-md-4" v-if="photo && !template">
          <div class="col-12 col-md-8 col-lg-6 col-xl-4">
            <div class="photo-profile">
              <img :src="photo" class="d-block mx-auto rounded-circle overflow-hidden" width="150" height="150" alt="">
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center pt-4">
          <div class="col-10 col-md-8" v-if="!template">
            <h1 class="my-0 text-center text-white fw-600 fz-20" v-if="names || surnames">{{ names + ' ' + surnames }}</h1>
            <h3 class="mt-2 mb-0 text-center text-dark-warning fw-600 fz-20" v-if="position">{{ position }}</h3>
            <ul class="mt-3 mx-0 p-0 list-unstyled text-center text-white fz-16 list-recorted">
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="phone_number">
                <v-icon class="mr-2 text-white">mdi-phone-outline</v-icon>
                {{ phone_number }}
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="address">
                <v-icon class="mr-2 text-white">mdi-map-marker</v-icon>
                {{ address }}
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="email">
                <v-icon class="mr-2 text-white">mdi-email</v-icon>
                <a :href="'mailto:'+email" target="_blank">{{ email }}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="website">
                <v-icon class="mr-2 text-white">mdi-web</v-icon>
                <a :href="website" target="_blank">{{ website }}</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import ProfileController from '../controllers/ProfileController';
import File from "../../../core/helpers/File";
import TranslationService from '@/services/translationService';

export default {
  name: 'ProfileView',
  data() {
    return {
      File,
      Helpers,
      header: 'company',
      contact_id: null,
      photo: '',
      names: '',
      surnames: '',
      position: '',
      phone_number: '',
      address: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      qr_code: '',
      dialog_qr: {},
      template: '',
      card_contact: '',
      error: '',
      currentLanguage: TranslationService.getCurrentLocale(), // Idioma inicial
      profile_controller: null,
    };
  },
  computed: {
    buttonText() {
      return this.currentLanguage === 'en' ? 'ENGLISH' : 'ESPAÑOL';
    },
    getPhotoUrl() {
      return this.photo.startsWith('http') ? this.photo : `https://soy.esenttia.co/zfiles/archivos/users/${this.photo}`;
    },
  },
  mounted() {
    console.log('Componente montado. Valor inicial de photo:', this.photo);
    this.profile_controller = new ProfileController(this);
  },
  methods: {
    translate(key) {
      return TranslationService.t(key);
    },
    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === 'en' ? 'es' : 'en';
      TranslationService.setLocale(this.currentLanguage);
      this.$emit('languageChanged', this.currentLanguage);
    },
    addContact() {
      this.profile_controller.addContact();
    },
    openQrCode() {
      this.profile_controller.openQrCode();
    },
    closeQrCode() {
      this.profile_controller.closeQrCode();
    },
  },
};
</script>

<style>
.language-toggle {
  margin-bottom: 20px;
}
</style>
