import UI from  '../../../core/helpers/UI';
// import File from "../../../core/helpers/File";
import ListService from '../services/ListService';
import TranslationService from "@/services/translationService"; // Importa TranslationService

class ListController {
  context;
  list_service;

  constructor(context) {
    this.context = context;
    this.list_service = new ListService();

    // Inicializa encabezados con traducción
    this.setHeaders();

    // Escucha cambios de idioma para actualizar encabezados
    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Cambio de idioma detectado en ListController");
      this.setHeaders(); // Actualiza encabezados dinámicamente
    });

    this._listUsers();
  }

  // Método para establecer encabezados traducidos
  setHeaders() {
    this.context.headers = [
      { text: TranslationService.t("table.headers.names"), value: "names" },
      { text: TranslationService.t("table.headers.surnames"), value: "surnames" },
      { text: TranslationService.t("table.headers.type"), value: "name_tipo" },
      { text: TranslationService.t("table.headers.state"), value: "state" },
      { text: TranslationService.t("table.headers.email"), value: "email" },
      { text: TranslationService.t("table.headers.actions"), value: "actions" },
    ];
    console.log("Encabezados traducidos:", this.context.headers); // Verifica en consola
  }

  _listUsers() {
    this.context.lists = [];
    UI.loading("show");
    this.list_service
      .listUsers()
      .then((r) => {
        if (r.success == true) {
          let items = [];

          if (r.data.items && r.data.items.length > 0) {
            r.data.items.forEach((v) => {
              items.push({
                ...v,
                first_name: v.pri_nombre != null && v.pri_nombre != "null" ? v.pri_nombre + " " : "",
                second_name: v.seg_nombre != null && v.seg_nombre != "null" ? v.seg_nombre : "",
                first_surname: v.pri_apellido != null && v.pri_apellido != "null" ? v.pri_apellido + " " : "",
                second_surname: v.seg_apellido != null && v.seg_apellido != "null" ? v.seg_apellido : "",
              });
            });

            items.forEach((v) => {
              this.context.lists.push({
                ...v,
                names: v.first_name + v.second_name,
                surnames: v.first_surname + v.second_surname,
              });
            });
          }

          UI.loading("hide");
        }
      })
      .catch((e) => {
        UI.loading("hide");
        UI.mensajeBad("", e.msg);
      });
  }

  toggleLockUser(usuario_id, state, position) {
    UI.loading("show");
    this.list_service
      .toggleLockUser(usuario_id)
      .then((r) => {
        if (r.success == true) {
          UI.mensajeOk("", r.msg);

          if (state == 2) {
            this.context.lists[position].estado = 1;
          } else {
            this.context.lists[position].estado = 2;
          }

          this.closeConfirm();

          UI.loading("hide");
        }
      })
      .catch((e) => {
        UI.loading("hide");
        UI.mensajeBad("", e.msg);
      });
  }

  openDialog(usuario_id) {
    this.context.$set(this.context.dialog_detail, usuario_id, true);
  }

  closeDetail(usuario_id) {
    this.context.$set(this.context.dialog_detail, usuario_id, false);
  }

  openConfirm(usuario_id, state, position) {
    this.context.usuario_id_confirm = usuario_id;
    this.context.state_confirm = state;
    this.context.position_confirm = position;
    this.context.$set(this.context.dialog_confirm, 0, true);
  }

  closeConfirm() {
    this.context.usuario_id_confirm = "";
    this.context.state_confirm = "";
    this.context.position_confirm = "";
    this.context.$set(this.context.dialog_confirm, 0, false);
  }
}

export default ListController;
