<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>{{ translate('breadcrumb.home') }}</li>
            <li>{{ translate('breadcrumb.users') }}</li>
            <li>{{ translate('breadcrumb.positionsList') }}</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">{{ translate('title.positionsList') }}</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <div class="row">
              <div class="col-md-8 col-lg-9 col-xl-10">
                <v-text-field
                  filled
                  v-model="search_list"
                  append-icon="mdi-magnify"
                  :label="translate('search.label')"
                  hide-details
                  clearable
                  class="my-0"
                ></v-text-field>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-2">
                <v-btn color="success" width="100%" min-height="56" @click="openDialog()">
                  {{ translate('button.createPosition') }}
                </v-btn>
              </div>
            </div>

            <div class="table-responsive mt-3 mt-md-4">
              <v-data-table
                class="my-0"
                :headers="headers"
                :items="lists"
                :items-per-page="10"
                :search="search_list"
              >
                <template v-slot:[`item.state`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded text color="primary" min-width="40" height="40" width="40" v-bind="attrs" v-on="on">
                        <v-icon class="w-auto h-auto fz-24">
                          {{ item.state == 1 ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.state == 1 ? translate('button.disable') : translate('button.enable') }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex align-items-center justify-content-center flex-nowrap">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="editPositionUser(item)">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ translate('button.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="danger" v-bind="attrs" v-on="on" @click="deletePositionUser(item.position_id)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ translate('button.delete') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_manage[0]" width="460">
          <v-card>
            <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
              <h1 class="my-0">{{ is_edit ? translate('title.editPosition') : translate('title.createPosition') }}</h1>
              <v-btn icon color="danger" @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-card-text class="scroll-modal-quotations">
              <v-form class="w-100" ref="form_manage" method="post" v-model="form_valid" lazy-validation @submit.prevent="managePositionUser()">
                <v-text-field
                  filled
                  :label="translate('form.positionName')"
                  v-model="name"
                  :rules="[rules.requerido]"
                ></v-text-field>

                <v-textarea
                  filled
                  :label="translate('form.positionDescription')"
                  v-model="description"
                  :rules="[rules.requerido]"
                ></v-textarea>

                <div class="d-sm-flex justify-content-center align-items-center">
                  <v-btn color="success" rounded type="submit">
                    {{ is_edit ? translate('button.update') : translate('button.save') }}
                  </v-btn>
                  <v-btn color="white" rounded @click="closeDialog()">
                    {{ translate('button.cancel') }}
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import PositionsUsersController from '../controllers/PositionsUsersController';
import TranslationService from "@/services/translationService";

export default {
  data() {
    return {
      Helpers,
      headers: [],
      lists: [],
      search_list: '',
      dialog_manage: {},
      form_valid: true,
      is_edit: false,
      position_id: null,
      name: '',
      description: '',
      rules: {
        requerido: value => !!value || this.translate('validation.required'),
      },
      positions_users_controller: null,
    };
  },

  mounted() {
    this.positions_users_controller = new PositionsUsersController(this);

    // Escucha cambios de idioma
    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Evento localeChanged recibido en PositionsUsers.vue");
      this.$forceUpdate();
    });
  },

  methods: {
    translate(key) {
      return TranslationService.t(key);
    },
    deletePositionUser(position_id) {
      this.positions_users_controller.deletePositionUser(position_id);
    },
    openDialog() {
      this.positions_users_controller.openDialog();
    },
    closeDialog() {
      this.positions_users_controller.closeDialog();
    },
    managePositionUser() {
      this.positions_users_controller.managePositionUser();
    },
    editPositionUser(info) {
      this.positions_users_controller.editPositionUser(info);
    },
  },
};
</script>
